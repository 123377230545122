<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <div class="row">
        <div class="date-box">
          <div class="date-choose">
            <div class="choose-item">
              <span
                :class="
                  selectTimeType === 1 || selectTimeType === 2
                    ? 'date-current'
                    : ''
                "
                >日</span
              >
              <div class="choose-detail">
                <span
                  :class="selectTimeType === 1 ? 'date-current' : ''"
                  @click="handleSetDate(1)"
                  >今日</span
                ><span
                  :class="selectTimeType === 2 ? 'date-current' : ''"
                  @click="handleSetDate(2)"
                  >昨日</span
                >
              </div>
            </div>
            <div class="choose-item">
              <span
                :class="
                  selectTimeType === 3 || selectTimeType === 4
                    ? 'date-current'
                    : ''
                "
                >周</span
              >
              <div class="choose-detail">
                <span
                  :class="selectTimeType === 3 ? 'date-current' : ''"
                  @click="handleSetDate(3)"
                  >本周</span
                ><span
                  :class="selectTimeType === 4 ? 'date-current' : ''"
                  @click="handleSetDate(4)"
                  >上周</span
                >
              </div>
            </div>
            <div class="choose-item">
              <span
                :class="
                  selectTimeType === 5 || selectTimeType === 6
                    ? 'date-current'
                    : ''
                "
                >月</span
              >
              <div class="choose-detail">
                <span
                  :class="selectTimeType === 5 ? 'date-current' : ''"
                  @click="handleSetDate(5)"
                  >本月</span
                ><span
                  :class="selectTimeType === 6 ? 'date-current' : ''"
                  @click="handleSetDate(6)"
                  >上月</span
                >
              </div>
            </div>
            <div class="choose-item">
              <el-popover placement="bottom" width="400" trigger="click">
                <div class="choose-date-current">
                  <span style="width: 60px">本期：</span>
                  <el-date-picker
                    v-model="params.selectTime"
                    style="width: 330px"
                    type="daterange"
                    align="right"
                    unlink-panels
                    :clearable="false"
                    range-separator="-"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    start-placeholder="本期开始日期"
                    end-placeholder="本期结束日期"
                    @change="handleSelectTimeChange"
                  >
                  </el-date-picker>
                </div>
                <div class="choose-date-current">
                  <span style="width: 60px">上期：</span>
                  <el-date-picker
                    v-model="params.lastTime"
                    style="width: 330px"
                    type="daterange"
                    align="right"
                    unlink-panels
                    :clearable="false"
                    range-separator="-"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    start-placeholder="上期开始日期"
                    end-placeholder="上期结束日期"
                    @change="handleSelectTimeChange"
                  >
                  </el-date-picker>
                </div>
                <span
                  class="customize"
                  slot="reference"
                  :class="selectTimeType === 7 ? 'date-current' : ''"
                  @click="handleSetDate(7)"
                  >自定义</span
                >
                <!-- <el-button slot="reference">click 激活</el-button> -->
              </el-popover>
            </div>
          </div>
          <div class="date-show">
            <div class="data-detail">
              本期:
              <span>{{
                params.selectTime
                  ? params.selectTime[0] + "/" + params.selectTime[1]
                  : ""
              }}</span>
            </div>
            <div class="data-detail">
              上期：<span>{{
                params.selectTime
                  ? params.lastTime[0] + "/" + params.lastTime[1]
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user
          :params="params"
          @userChange="(name) => (this.userName = name)"
          @deptChange="(dept) => (this.deptName = dept)"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="searchLoading"
        >
          搜索
        </el-button>
        <el-button type="info" :loading="searchLoading" @click="handleReset">
          重置
        </el-button>
      </div>
    </div>
    <div class="loading-box" v-loading="searchLoading">
      <!-- 汇总数据 -->
      <div class="data-total">
        <div
          class="total-item-box"
          v-for="(item, index) in totalData"
          :key="index"
        >
          <div
            class="total-item total-item-goto"
            v-for="(item2, index2) in item.value"
            :key="index2"
          >
            <h4 class="total-1">{{ item2.name }}</h4>
            <div class="total-item-value">
              <div class="total-value">
                <span class="text">{{ item2.value }}</span>
                <span class="unit">{{ item2.valueUnit }}</span>
              </div>
              <!-- 负数 -->
              <template v-if="item2.rate !== null && item2.rate.includes('-')">
                <!-- 反向上升下降 -->
                <template v-if="!item2.reverseType">
                  <div class="change-value-green">
                    <span>{{
                      item2.rate ? item2.rate + item2.rateUnit : ""
                    }}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="change-value-red">
                    <span>{{
                      item2.rate ? item2.rate + item2.rateUnit : ""
                    }}</span>
                  </div>
                </template>
              </template>
              <!-- 正数 -->
              <template
                v-else-if="
                  item2.rate !== null &&
                  (item2.rate > 0 || item2.rate.includes(','))
                "
              >
                <!-- 反向上升下降 -->
                <template v-if="!item2.reverseType">
                  <div class="change-value-red">
                    <span>{{
                      item2.rate ? "+" + item2.rate + item2.rateUnit : ""
                    }}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="change-value-green">
                    <span>{{
                      item2.rate ? "+" + item2.rate + item2.rateUnit : ""
                    }}</span>
                  </div>
                </template>
              </template>
              <template v-else-if="item2.rate == 0">
                <div class="change-value-orange">
                  <span>{{
                    item2.rate ? item2.rate + item2.rateUnit : ""
                  }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- echart table 数据 -->
      <div class="data-box" v-loading="changeLoading">
        <el-row :gutter="10">
          <el-col :span="5">
            <div class="consume-box">
              <p class="consume-title">消耗分布</p>
              <div id="consumeChart"></div>
            </div>
            <div class="consume-box">
              <p class="consume-title">回本分布</p>
              <div id="returnChart"></div>
            </div>
            <!-- roi环比top -->
            <div class="roi-box">
              <p class="roi-title">
                <span>ROI环比TOP10</span>
                <span class="title-button"
                  ><span
                    @click="handleChangeRoi(0)"
                    :class="roiSelected === 0 ? 'selected' : ''"
                    >增长</span
                  >
                  <span
                    :class="roiSelected === 1 ? 'selected' : ''"
                    @click="handleChangeRoi(1)"
                    >下降</span
                  ></span
                >
              </p>
              <div id="roiChart"></div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="tag-bar">
              <el-tabs
                type="card"
                :value="dataType"
                @tab-click="handleChangeTag"
              >
                <el-tab-pane label="公众号" name="1"></el-tab-pane>
                <el-tab-pane label="书籍" name="2"></el-tab-pane>
                <el-tab-pane label="投手" name="3"></el-tab-pane>
              </el-tabs>
              <el-table
                @sort-change="handleSortChange"
                :data="cacheTableData"
                class="table"
                style="width: 100%"
                height="486px"
              >
                <el-table-column
                  fixed
                  prop="name"
                  :label="
                    dataType == 1 ? '公众号' : dataType == 2 ? '书籍' : '投手'
                  "
                  min-width="80"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="消耗" header-align="center">
                  <el-table-column label="现金" header-align="center">
                    <el-table-column
                      prop="cashCostAmt"
                      label="金额"
                      min-width="55"
                      sortable="custom"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.cashCostAmt + "万" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="comparingCashCostAmtRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingCashCostAmtRate + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    prop="invalidCostRate"
                    label="无效占比"
                    min-width="80"
                    sortable="custom"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.invalidCostRate + "%" }}
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="回本" header-align="center">
                  <el-table-column label="税后充值" header-align="center">
                    <el-table-column
                      prop="totalReturnAmt"
                      label="累计"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.totalReturnAmt + "万" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="returnAmt"
                      label="区间"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.returnAmt + "万" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="comparingReturnAmtRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingReturnAmtRate + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="D1天" header-align="center">
                    <el-table-column
                      prop="firstReturnRate"
                      label="回本率"
                      sortable="custom"
                      min-width="61"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.firstReturnRate + "%" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="comparingFirstReturnRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingFirstReturnRate + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="ROI" header-align="center">
                    <el-table-column
                      prop="totalRoi"
                      label="累计"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.totalRoi + "%" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="roi"
                      label="区间"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.roi + "%" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="comparingRoi"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingRoi + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="付费率" header-align="center">
                  <el-table-column label="D1天" header-align="center">
                    <el-table-column
                      prop="firstPayRate"
                      label="付费率"
                      sortable="custom"
                      min-width="61"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.firstPayRate + "%" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="comparingFirstPayRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingFirstPayRate + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    prop="totalPayRate"
                    label="累计"
                    sortable="custom"
                    min-width="55"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.totalPayRate + "%" }}
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="粉丝" header-align="center">
                  <el-table-column label="增粉" header-align="center">
                    <el-table-column
                      prop="newUser"
                      label="人数"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="comparingNewUserRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.comparingNewUserRate + "%" }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="单粉价" header-align="center">
                    <el-table-column
                      prop="newUserPrice"
                      label="单价"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="comparingNewUserPriceRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <!-- <template slot-scope="scope">
                        {{ scope.row.comparingNewUserPriceRate + "%" }}
                      </template> -->
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="转换成本" header-align="center">
                    <el-table-column
                      prop="payPrice"
                      label="单价"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="comparingPayPriceRate"
                      label="环比"
                      label-class-name="huanbi"
                      sortable="custom"
                      min-width="55"
                      header-align="center"
                    >
                      <!-- <template slot-scope="scope">
                        {{ scope.row.comparingPayPriceRate + "%" }}
                      </template> -->
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    prop="img"
                    label="评价"
                    min-width="30"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      <img
                        class="emoji"
                        v-if="scope.row.img === 1"
                        src="../../../assets/img/laugh.png"
                        alt=""
                      />
                      <img
                        class="emoji"
                        v-if="scope.row.img === 2"
                        src="../../../assets/img/noface.png"
                        alt=""
                      />
                      <img
                        class="emoji"
                        v-if="scope.row.img === 3"
                        src="../../../assets/img/cry.png"
                        alt=""
                      />
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin-top: 10px; text-align: right"
                background
                @current-change="tableStaticPage"
                :current-page="page"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="totalPage"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getPutIntervalLabel, getPutIntervalExcel } from "@/api/statics";
import {
  formatTime,
  getCurrentWeek,
  getLastWeek,
  getLastAllWeek,
  getPresentMonth,
  getPreMonth,
  getPreMonthByCurrent,
} from "@/assets/js/utils";
import echarts from "echarts";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { mapActions } from "vuex";
import { getBookList } from "@/api/account";
export default {
  data() {
    return {
      params: {
        selectTime: [],
        lastTime: [],
      },
      bookList: [],
      userName: null,
      deptName: null,
      consumeChart: null,
      returnChart: null,
      roiChart: null,
      tableData: [],
      cacheTableData: [],
      totalData: [],
      roiData: null,
      roiSelected: 0,
      selectTimeType: 5,
      dataType: "1",
      searchLoading: false,
      changeLoading: false,
      sortName: null,
      sortDesc: null,
      page: 1,
      pageSize: 15,
      totalPage: 0,
    };
  },
  components: {
    SelectDeptUser,
    SelectPlatformChannel,
  },
  methods: {
    // 表格静态分页
    tableStaticPage(page) {
      this.page = page;
      this.cacheTableData = this.tableData.slice(
        this.pageSize * (this.page - 1),
        this.pageSize * this.page
      );
    },
    // 排序处理
    handleSortChange({ prop, order }) {
      this.sortName = prop;
      if (!order) {
        this.sortDesc = null;
        this.sortName = null;
      } else {
        this.sortDesc = order === "descending";
      }
      this.handleGetTableData();
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 环比切换增长下降
    handleChangeRoi(type) {
      this.roiSelected = type;
      this.setRoiOption(this.roiData[type]);
    },
    // 自定义时间
    handleSelectTimeChange() {},
    // 切换时间
    handleSetDate(type) {
      switch (type) {
        case 1:
          this.params.selectTime = [
            formatTime(new Date(), "yyyy-MM-dd"),
            formatTime(new Date(), "yyyy-MM-dd"),
          ];
          this.params.lastTime = [
            formatTime(new Date().getTime() - 3600 * 1000 * 24, "yyyy-MM-dd"),
            formatTime(new Date().getTime() - 3600 * 1000 * 24, "yyyy-MM-dd"),
          ];
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 2:
          this.params.selectTime = [
            formatTime(new Date().getTime() - 3600 * 1000 * 24, "yyyy-MM-dd"),
            formatTime(new Date().getTime() - 3600 * 1000 * 24, "yyyy-MM-dd"),
          ];
          this.params.lastTime = [
            formatTime(
              new Date().getTime() - 3600 * 1000 * 24 * 2,
              "yyyy-MM-dd"
            ),
            formatTime(
              new Date().getTime() - 3600 * 1000 * 24 * 2,
              "yyyy-MM-dd"
            ),
          ];
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 3:
          this.params.selectTime = [getCurrentWeek()[1], getCurrentWeek()[0]];
          this.params.lastTime = [getLastWeek()[1], getLastWeek()[0]];
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 4:
          this.params.selectTime = [getLastAllWeek()[1], getLastAllWeek()[0]];
          this.params.lastTime = [
            getLastAllWeek(true)[1],
            getLastAllWeek(true)[0],
          ];
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 5:
          this.params.selectTime = getPresentMonth();
          this.params.lastTime = getPreMonthByCurrent();
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 6:
          this.params.selectTime = getPreMonth();
          this.params.lastTime = getPreMonthByCurrent(true);
          this.selectTimeType = type;
          this.handlePageChange();
          break;
        case 7:
          this.selectTimeType = type;
        default:
          this.params.selectTime = getPresentMonth();
          this.params.lastTime = getPreMonthByCurrent();
          this.selectTimeType = type;
          break;
      }
    },
    // 切换标签
    handleChangeTag(value) {
      this.dataType = value.name;
      this.handleGetTableData();
    },
    //设置echarts
    // 设置书籍消耗图形数据
    setConsumeOption(data) {
      // let tmpData = data.map((item) => {
      //   return {
      //     name: item.bookName,
      //     value: item.cashCostAmt,
      //     booksId: item.booksId,
      //   };
      // });
      this.consumeChart.setOption(
        {
          tooltip: {
            trigger: "item",
            formatter: "{b}<br/>{c}万",
          },
          color: [
            "#6273E0",
            "#3F80FF",
            "#AB74E9",
            "#22C7B1",
            "#FF9163",
            "#FFB85E",
            "#00BF8A",
            "#FB817F",
          ],
          series: [
            {
              left: "left",
              width: "100%",
              height: "100%",
              type: "treemap",
              data: data,
              roam: false,
              nodeClick: false,
              breadcrumb: { show: false },
            },
          ],
        },
        true
      );
    },
    // 设置roi图形数据
    setRoiOption(sendData) {
      let data = [...sendData];
      data = data.reverse();
      this.roiChart.setOption({
        color: ["#579BF4"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br/>{c}%",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "5%",
          height: "95%",
          containLabel: true,
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: data.map((item) => {
            return item.name;
          }),
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
        },
        series: [
          {
            // width: "100%",
            height: "100%",
            type: "bar",
            data: data.map((item) => {
              return item.value;
            }),
          },
        ],
      });
    },
    // 设置分布图形数据
    setReturnOption(data) {
      this.returnChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}<br/>{c}万",
        },
        color: [
          "#6273E0",
          "#3F80FF",
          "#AB74E9",
          "#22C7B1",
          "#FF9163",
          "#FFB85E",
          "#00BF8A",
          "#FB817F",
        ],
        series: [
          {
            left: "left",
            width: "100%",
            height: "100%",
            type: "treemap",
            data: data,
            roam: false,
            nodeClick: false,
            breadcrumb: { show: false },
          },
        ],
      });
    },
    handleReset() {
      this.params = {
        selectTime: [],
        lastTime: [],
      };
      this.sortName = null;
      this.sortDesc = null;
      this.handleSetDate();
      this.handlePageChange();
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 初始化echart
    initEchart() {
      this.consumeChart = echarts.init(document.getElementById("consumeChart"));
      this.returnChart = echarts.init(document.getElementById("returnChart"));
      this.roiChart = echarts.init(document.getElementById("roiChart"));

      window.onresize = () => {
        this.consumeChart.resize();
        this.roiChart.resize();
      };
    },
    handleGetTableData() {
      this.changeLoading = true;
      getPutIntervalExcel(this.handleGetParams())
        .then((res) => {
          this.setConsumeOption(res.costAmts);
          this.setReturnOption(res.returnAmts);
          this.roiData = [res.addRoi, res.subRoi];
          this.setRoiOption(this.roiData[this.roiSelected]);
          this.tableData = res.lists;
          this.page = 1;
          this.totalPage = this.tableData.length;
          this.tableStaticPage(1);
        })
        .finally(() => {
          this.searchLoading = false;
          this.changeLoading = false;
        });
    },
    // 搜索处理
    handlePageChange() {
      this.searchLoading = true;
      getPutIntervalLabel(this.handleGetParams()).then((res) => {
        this.totalData = [
          {
            name: "part1",
            value: [
              {
                name: "现金投放",
                value: res.cashCostAmt,
                valueUnit: "万",
                rate: res.comparingCashCostAmtRate,
                rateUnit: "%",
              },
              {
                name: "无效占比",
                value: res.invalidCostRate,
                valueUnit: "%",
                rate: res.comparingInvalidCostRate,
                rateUnit: "%",
                reverseType: true,
              },
            ],
          },
          {
            name: "part2",
            value: [
              {
                name: "累计回本",
                value: res.totalReturnAmt,
                valueUnit: "万",
                rate: null,
                rateUnit: null,
              },
              {
                name: "区间回本",
                value: res.returnAmt,
                valueUnit: "万",
                rate: res.comparingReturnAmtRate,
                rateUnit: "%",
              },
            ],
          },
          {
            name: "part3",
            value: [
              {
                name: "D1回本率",
                value: res.firstReturnRate,
                valueUnit: "%",
                rate: res.comparingFirstReturnRate,
                rateUnit: "%",
              },
              {
                name: "累计ROI",
                value: res.totalRoi,
                valueUnit: "%",
                rate: null,
                rateUnit: null,
              },
              {
                name: "区间ROI",
                value: res.roi,
                valueUnit: "%",
                rate: res.comparingRoi,
                rateUnit: "%",
              },
            ],
          },
          {
            name: "part4",
            value: [
              {
                name: "增粉",
                value: res.newUser,
                valueUnit: "人",
                rate: res.comparingNewUserRate,
                rateUnit: "%",
              },
              {
                name: "单粉价",
                value: res.newUserPrice,
                valueUnit: "元/人",
                rate: res.comparingNewUserPriceRate,
                rateUnit: "元/人",
                reverseType: true,
              },
              {
                name: "转换成本",
                value: res.payPrice,
                valueUnit: "元/人",
                rate: res.comparingPayPriceRate,
                rateUnit: "元/人",
                reverseType: true,
              },
            ],
          },
          {
            name: "part5",
            value: [
              {
                name: "D1付费率",
                value: res.firstPayRate,
                valueUnit: "%",
                rate: res.comparingFirstPayRate,
                rateUnit: "%",
              },
              {
                name: "累计付费率",
                value: res.totalPayRate,
                valueUnit: "%",
                rate: null,
                rateUnit: null,
              },
            ],
          },
        ];
        this.addRouterHistory({
          path: this.$route.path,
          params: {
            ...this.handleGetParams(),
            currentOfficial: this.params.currentOfficial,
            selectTime: this.params.selectTime,
            lastTime: this.params.lastTime,
            selectTimeType: this.selectTimeType,
          },
        });
      });
      this.handleGetTableData();
    },
    // 参数处理
    handleGetParams() {
      let tmpParam = {
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        lastPeriodStartDate: this.params.lastTime[0],
        lastPeriodEndDate: this.params.lastTime[1],
        dataType: this.dataType,
        originPlatform: this.params.originPlatform,
        adminUserId: this.params.adminUserId,
        deptId: this.params.deptId,
        accountChannelId: this.params.accountChannelId,
        booksId: this.params.booksId,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
      };
      return tmpParam;
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
  },
  created() {
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      this.getBookById(this.params.booksId);
    }
    if (this.params.selectTimeType) {
      this.handleSetDate(this.params.selectTimeType);
    } else {
      this.handleSetDate(5);
    }
    this.handlePageChange();
    this.getBook();
  },
  mounted() {
    this.initEchart();
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    echarts.dispose(this.roiChart);
    echarts.dispose(this.returnChart);
    echarts.dispose(this.consumeChart);
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
/**
*( (  \.---./  ) )
* '.__/o   o\__.'
*区间分析相关样式         ------     by devT
**/
.choose-date-current {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.date-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 750px;
  margin-right: 20px !important;
  box-shadow: 0px 0px 10px 0px #d8dcdf;
  border-radius: 5px;
  padding: 2px 8px;
}
.date-choose {
  font-size: 12px;
  color: #1f2d3d;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .choose-item {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .date-current {
      color: #22c7b1;
    }
    .customize {
      cursor: pointer;
    }
    .choose-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
        padding: 0 10px;
      }
    }
  }
}
.date-show {
  font-size: 12px;
  color: #1f2d3d;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-item-box {
  box-shadow: 0px 0px 10px 0px #d8dcdf;
  border-radius: 6px;
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
  padding: 5px;
  margin-bottom: 5px;
}
.total-item-box:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.data-total {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  .total-item {
    padding: 4px 0;
    margin-right: 4px;
    min-width: 100px;
    h4 {
      height: 14px;
      line-height: 14px;
      color: #b4b8be;
      font-size: 13px;
      font-weight: 400;
      padding-left: 7px;
      border-radius: 2px;
    }
    .total-1 {
      border-left: 4px solid #3f80ff;
    }
    .total-2 {
      border-left: 4px solid #22c7b1;
    }
    .total-3 {
      border-left: 4px solid #ffb85e;
    }
    .total-4 {
      border-left: 4px solid #6273e0;
    }
    .total-item-value {
      padding: 5px 0 5px 10px;
      // padding-right:0;
      .icon-xiajiang {
        font-size: 12px;
      }
    }
    .total-value {
      line-height: 40px;
      font-size: 24px;
      font-weight: bold;
      color: #1f2d3d;
      .unit {
        margin-left: 2px;
        font-size: 12px;
        color: #475669;
      }
    }
    .change-value-red {
      margin-left: auto;
      font-size: 12px;
      color: #fd5d5a;
    }
    .change-value-green {
      margin-left: auto;
      font-size: 12px;
      color: #22c7b1;
    }
    .change-value-orange {
      margin-left: auto;
      font-size: 12px;
      height: 16px;
      line-height: 16px;
      color: #ffb85e;
    }
  }
}
.data-box {
  #consumeChart,
  #returnChart,
  #roiChart {
    min-height: 160px;
  }
  margin-top: 10px;
  .consume-box {
    .consume-title {
      color: #969696;
      font-size: 12px;
      padding: 5px;
    }
  }
  .table {
    .emoji {
      width: 25px;
      height: 25px;
    }
  }
  .roi-box {
    .roi-title {
      color: #969696;
      font-size: 12px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-button {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .selected {
          color: #579bf4;
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
  .tag-bar {
    // margin-top: 20px;
    // /deep/ .el-tabs__content{
    //   display: none;
    // }
    // /deep/ .el-tabs--border-card{
    //   box-shadow: none;
    //   border-bottom: none;
    // }
    /deep/ .el-tabs__header {
      margin-bottom: 0 !important;
    }
    // 表头
    /deep/ .el-table--border th,
    /deep/.el-table .has-gutter tr th {
      border-bottom: 1px solid rgb(235, 238, 245) !important;
      border-right: 1px solid rgb(235, 238, 245) !important;
    }
    /deep/ .cell {
      transform: scale(0.8);
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    /deep/ .el-table td {
      padding: 0 2px !important;
    }
    /deep/ .huanbi {
      color: #fd5d5a !important;
    }
    /deep/ .el-table--small th {
      padding: 0 !important;
    }
  }
  .el-table--small {
    font-size: 12px !important;
  }
}
/**
*end by devT
**/
</style>
